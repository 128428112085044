import React from 'react';

import SEO from '../components/SEO';
import logo from '../images/logo.png';

const IndexPage = () => (
	<>
		<SEO title="Home" />
		<div className="bg-white h-screen container mx-auto p-4 flex flex-col items-center justify-center">
			<div className="flex flex-col items-center justify-center space-y-10">
				<p className="max-w-md text-left text-gray-500">
					Beste klant,
					<br />
					<br /> Voetzorg Iris sluit vanaf 1 mei 2024 definitief haar deuren.
					<br />
					<br />
					Alle ingeplande afspraken worden automatisch geannuleerd.
					<br />
					<br /> Ik wil u van harte bedanken voor het jarenlang vertrouwen in
					mijn advies en verzorgingen. Een grote dank voor alle fijne momenten,
					soms met een traan, maar vooral met heel veel plezier. <br />
					<br />
					Het was me een hele eer uw voeten te mogen verzorgen!
					<br />
					<br />– Iris
				</p>
				<img className="h-20 self-center" src={logo} alt="logo Voetzorg Iris" />
			</div>
		</div>
	</>
);

export default IndexPage;
