import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import metaImage from '../images/meta.png';

const SEO = ({ description = '', keywords = '', meta = [], title }) => {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
						keywords
					}
				}
			}
		`
	);

	const defaultDescription = site.siteMetadata?.description;
	const defaultTitle = site.siteMetadata?.title;
	const defaultKeywords = site.siteMetadata?.keywords;

	return (
		<Helmet
			htmlAttributes={{
				lang: 'nl-BE',
			}}
			title={title}
			titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
			meta={[
				{
					name: 'keywords',
					content: keywords || defaultKeywords,
				},
				{
					name: 'description',
					content: description || defaultDescription,
				},
				{
					property: 'og:title',
					content: title,
				},
				{
					property: 'og:description',
					content: description || defaultDescription,
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					property: 'og:image',
					content: metaImage,
				},
				{
					name: 'twitter:card',
					content: 'summary',
				},
				{
					name: 'twitter:creator',
					content: site.siteMetadata?.author || '',
				},
				{
					name: 'twitter:title',
					content: title,
				},
				{
					name: 'twitter:description',
					content: description || defaultDescription,
				},
				{
					name: 'twitter:image',
					content: metaImage,
				},
			].concat(meta)}
		/>
	);
};

SEO.propTypes = {
	description: PropTypes.string,
	keywords: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
};

export default SEO;
